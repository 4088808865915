import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

// Constants
const UPLOAD_TIMEOUT = 1000;  // Timeout in milliseconds. The timeout is 2 second because each chunk is 1MB. On a bandwidth of 8Mbps, a 1MB chunk should upload in 1 second.
const CHUNKING_THRESHOLD = 4194304;  // 4MB; the files over this size will go through chunking
const CHUNK_SIZE = 1048576; // 1MB The size of a single chunk

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      // Create a hidden input to store the key
      const keyInput = document.createElement("input");
      keyInput.type = "hidden";
      keyInput.name = "post[feature_image_keys][]";
      keyInput.value =  file.name + "|" + file.size + "|" + file.upload.uuid;
      console.log("Assigning key reference " + keyInput.value);
      this.element.appendChild(keyInput);
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, UPLOAD_TIMEOUT);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
      // Also remove the corresponding key input
      const keyInput = this.element.querySelector(`input[value="${file.uploadKey}"]`);
      if (keyInput) {
        keyInput.remove();
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("sending", function(file, xhr, formData) {
      formData.append("filesize", file.size);
      formData.append("fileName", file.upload.uuid);
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 5120;
  }

  get filesizeBase() {
    return this.data.get("filesizeBase") || 1024;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
    this.chunkSize = CHUNKING_THRESHOLD;
    this.maxRetries = 3; // Number of retries
    this.retryCount = 0; // Track retry attempts
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    // If file size is greater than chunk size, upload in chunks
    if (this.file.size > this.chunkSize) {
      this.uploadInChunks(xhr);
    } else {
      this.bindProgressEvent(xhr);
      this.emitDropzoneUploading();
    }
  }

  uploadInChunks(xhr) {
    let start = 0;
    const totalSize = this.file.size;
    const fileName = this.file.upload.uuid;
    console.log("Uploading file " + fileName + " in chunks...");

    const uploadNextChunk = () => {
      // Check if the upload is canceled
      if (this.file.status === Dropzone.CANCELED) {
        console.log('Upload canceled');
        return;
      }
      const end = Math.min(start + CHUNK_SIZE, totalSize);
      const chunk = this.file.slice(start, end);
      const formData = new FormData();
      console.log("Uploading chunk of size " + CHUNK_SIZE + " from " + start + " to " + end);
      formData.append("file", fileName);
      formData.append("fileSize", totalSize);
      formData.append("content", chunk);
      formData.append("start", start);
      formData.append("end", end);
      formData.append("chunkSize", CHUNK_SIZE);

      // Make an AJAX call here
      fetch('/posts/upload_file_chunk', {
        method: 'POST',
        body: formData
      }).then(response => {
        if (!response.ok) {
          // Retry logic based on server response till the number of retries have exhausted
          if (this.retryCount < this.maxRetries && (response.status == 413 || response.status == 500)) {
            console.log(`Upload chunk failed (status ${response.status}). Retrying (${this.retryCount + 1}/${this.maxRetries})...`);
            this.retryCount++;
            setTimeout(uploadNextChunk, UPLOAD_TIMEOUT);
          } else {
            throw new Error('Failed to upload chunk after retries');
          }
        } else {
          // Update progress and handle next chunk if upload is successful
          const progress = (1 - (totalSize - end) / totalSize) * 100;
          findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`;
          start = end;
          if (start < totalSize) {
            // Reset retry count on successful upload
            this.retryCount = 0;
            // Continue with the next chunk after a minor second
            setTimeout(uploadNextChunk, 100);
          } else {
            console.log("Upload complete!");
            this.emitDropzoneSuccess(); // Emit success event if all chunks are uploaded
          }
        }
      }).catch(error => {
        // Handle upload failure
        console.error('Upload error:', error);
        this.emitDropzoneError(error);
      });
    };
    uploadNextChunk();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    filesizeBase: controller.filesizeBase,
    autoQueue: false
  });
}
